
import { PoolWithShares, PoolType, AnyPool, Pool } from '@/services/pool/types';
import { TokenPrices } from '@/services/coingecko/api/price.service';
import {
  ClaimType,
  ClaimProviderService,
} from '@/services/pool/claim.provider';
import {
  computed,
  defineComponent,
  ref,
  PropType,
  watch,
  ComputedRef,
  toRef,
} from 'vue';

import usePoolQuery from '@/composables/queries/usePoolQuery';
import useWeb3 from '@/services/web3/useWeb3';
import { POOLS } from '@/constants/pools';
import communityAssetBackImg from '@/assets/images/coins/community-border.svg';
import useStake from '@/composables/PolarisFinance/useStake';
import { TransactionResponse } from '@ethersproject/providers';
import useTransactions from '@/composables/useTransactions';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import MyPoolInvsetmentFiat, {
  MyPollInvestmentFiatType,
} from '@/components/pool/MyPoolInvsetmentFiat.vue';
import useEthers from '@/composables//useEthers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import {
  absMaxApr,
  isMigratablePool,
  isStableLike,
  orderedPoolTokens,
  orderedTokenAddresses,
  totalAprLabel,
  usePool,
} from '@/composables/usePool';
import {
  BigNumberToString,
  sunriseNameToAddress,
  SPOLAR,
  getDisplayBalance,
} from '@/composables/PolarisFinance/utils';
import { BigNumber } from 'ethers';
import useBreakpoints from '@/composables/useBreakpoints';

import polarImg from '@/pages/polar.svg';
import orbitalImg from '@/pages/orbital.svg';
import uspImg from '@/pages/usp.svg';
import ethernalImg from '@/pages/ethernal.svg';
import binarisImg from '@/pages/binaris.svg';

const singlePools = [
      { name: 'POLAR', id: '0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86', logo:polarImg},
      { name: 'ORBITAL', id: '0x3AC55eA8D2082fAbda674270cD2367dA96092889', logo:orbitalImg },
      { name: 'BINARIS', id: '0xafE0d6ca6AAbB43CDA024895D203120831Ba0370', logo:binarisImg },
      { name: 'USP', id: '0xa69d9Ba086D41425f35988613c156Db9a88a1A96', logo:uspImg },
      { name: 'ETHERNAL', id: '0x17cbd9C274e90C537790C51b4015a65cD015497e', logo:ethernalImg },
      // { name: 'PBOND', id: '0x3a4773e600086A753862621A26a2E3274610da43' },
      // { name: 'OBOND', id: '0x192bdcdd7b95A97eC66dE5630a85967F6B79e695' },
      // { name: 'BBOND', id: '0xfa32616447C51F056Db97BC1d0E2D4C0c4D059C9' },
      // { name: 'USPBOND', id: '0xcE32b28c19C61B19823395730A0c7d91C671E54b' },
      // { name: 'EBOND', id: '0x266437E6c7500A947012F19A3dE96a3881a0449E' },
    ];

export default defineComponent({
  data() {
    return {
      claims: [] as Array<ClaimType>,
      noClaims: true,
      totalClaims: 0,
      totalPoolFiatValue: 0,
      xpolarPoolQuery: usePoolQuery(
        '0x23a8a6e5d468e7acf4cc00bd575dbecf13bc7f78000100000000000000000015'
      ),
      poolTotalFiatValues: [],
    };
  },
  watch: {
    pools(newValue, oldValue) {
      if (newValue.length != 0) this.fetchClaimsIfPossible();
    },
    prices(newValue, oldValue) {
      if (newValue != undefined) this.fetchClaimsIfPossible();
    },
    async account() {
      this.fetchClaimsIfPossible();
    },
  },
  components: {
    MyPoolInvsetmentFiat,
},
  props: {
    pools: {
      type: Array as PropType<Array<PoolWithShares>>, //PoolWithShares AnyPool
      default: null,
    },
    prices:{
      type: Object as PropType<ComputedRef<TokenPrices>>,
      default: null,
    },
    noApr: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    async claimXpolar(claim) {
      const { withdraw } = useStake();
        const tx = await withdraw(
          claim.address,
          BigNumber.from(0),
          this.getProvider()
        );
        this.txHandler(tx);
        this.txListener(tx, {
          onTxConfirmed: () => {
            this.fetchClaims();
          },
          onTxFailed: () => {},
        });
      
    },
    fetchClaimsIfPossible() {
      if (this.pools.length != 0 && this.prices != undefined) {
        this.fetchClaims();
      }
    },
    async claimXpolarAll() {
      const { claimAll } = useStake();
      const tx = await claimAll(this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.fetchClaims();
        },
        onTxFailed: () => {},
      });
    },
    async fetchClaims() {
      const claimer = new ClaimProviderService(
        this.pools,
        singlePools,
        this.prices,
        this.xpolarPoolQuery,
        this.account
      );
      claimer.init();
      claimer.claimReceived = (claim: ClaimType) => {
        let existingIndex = -1;
        this.claims.forEach(existingClaim => {
          if (existingClaim.address == claim.address) {
            existingIndex = this.claims.indexOf(existingClaim);
          }
        });
        if (existingIndex != -1) {
          this.claims[existingIndex] = claim;
        } else {
          this.claims.push(claim);
          this.totalClaims = this.totalClaims + Number(claim.xpolarToClaim);
        }

        // this.claimsCount = this.claims.length;
        // this.$forceUpdate;
      };
      claimer.fetchAll();
    },
    refreshTotalValue() {
      this.totalPoolFiatValue = 0;
      for (const [key, value] of Object.entries(this.poolTotalFiatValues)) {
        this.totalPoolFiatValue = this.totalPoolFiatValue + value;
      }
    },
  },
  setup(props) {
    
    /**
     * COMPOSABLES
     */
    const { fNum2, toFiat } = useNumbers();
    const { tokens, balances, balanceFor } = useTokens();
    const { upToMediumBreakpoint, isMobile, isDesktop } = useBreakpoints();
    const { txListener } = useEthers();
    /**
     * COMPUTED
     */
    const { account, connector, startConnectWithInjectedProvider } = useWeb3();
    const { getProvider } = useWeb3();
    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'claim',
        summary: 'claim staking reward',
      });
    };
    const { addTransaction } = useTransactions();
    const bptBalance = computed((pool): string => balanceFor(pool.address));

    /**
     * METHODS
     */

    function iconAddresses(claim: ClaimType) {
      if (claim.pool != undefined)
        return POOLS.Metadata[claim.pool.id]?.hasIcon
          ? [claim.pool.address]
          : orderedTokenAddresses(claim.pool);
    }

    function getRooterLink(claim){
      if(claim.pool!=undefined)
        return '/pool/' + claim.pool.id;
      else{
        for(var i=0;i<singlePools.length;i++){
          if(singlePools[i].id == claim.address)
            return '/singlestake/'+singlePools[i].name.toLowerCase();
        }
      }
      return '';
    }
    function getSingleStakeLogo(claim){
      for(var i=0;i<singlePools.length;i++){
        if(singlePools[i].id == claim.address)
        return singlePools[i].logo;
      }
    }
    function getSingleStakeName(claim){
      for(var i=0;i<singlePools.length;i++){
        if(singlePools[i].id == claim.address && isDesktop.value)
          return singlePools[i].name + ' - Single Stake';
        else if(singlePools[i].id == claim.address && isMobile.value)
          return singlePools[i].name ;
      }
    }

    return {
      account,
      iconAddresses,
      orderedPoolTokens,
      communityAssetBackImg,
      // methods
      getProvider,
      txHandler,
      txListener,
      BigNumberToString,
      isMobile,
      isDesktop,
      fNum2,
      FNumFormats,
      getRooterLink,
      getSingleStakeLogo,
      getSingleStakeName,
    };
  },
  created() {},
  beforeUpdate() {},
  mounted() {},
  updated() {
    const target_copy: MyPollInvestmentFiatType = Object.assign(
      {},
      this.$refs['poolTotalFiatValues']
    ) as MyPollInvestmentFiatType;
    // this.totalPoolFiatValue +=;
    if (target_copy.fiatNumber != undefined) {
      this.poolTotalFiatValues[target_copy.pool.address] =
        target_copy.fiatNumber;
    }
    this.refreshTotalValue();
  },
});
