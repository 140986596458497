
import { computed, defineComponent, ref, toRefs, PropType,watch,ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { PoolWithShares } from '@/services/pool/types';
import { PoolToken } from '@/services/pool/types';

import { POOLS } from '@/constants/pools';
import {
  absMaxApr,
  isMigratablePool,
  isStableLike,
  orderedPoolTokens,
  orderedTokenAddresses,
  totalAprLabel,
} from '@/composables/usePool';

import {AprProviderService} from '@/services/pool/apr.provider';
import { TokenPrices } from '@/services/coingecko/api/price.service';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  data() {
    return {
        aprs: {},
        xpolarPoolQuery: usePoolQuery(
          '0x23a8a6e5d468e7acf4cc00bd575dbecf13bc7f78000100000000000000000015'
        ),
    }},
  components: {
  },
  props: {
    pool: {
      type: Object as PropType<PoolWithShares>,
      default: null,
    },
    prices:{
      type: Object as PropType<ComputedRef<TokenPrices>>,
      default: null,
    },
    noApr:{
        type:Boolean,
        default:false,
    }
  },
  emits: ['click'],
  methods:{
    async fetchApr() {
      if (!this.pool) {
        await new Promise((resolve, reject) => {
          const loop = () =>
          this.pool !== undefined
              ? resolve(this.pool)
              : setTimeout(loop, 100);
          loop();
        });
      }

      /*const aprsPromises: any[] = [];
      for (var i = 0; i < this.data.length; i++) {
        aprsPromises.push(this.fetch(this.data[i]));
      }
      const aprs = await Promise.all(aprsPromises);
      for (var i = 0; i < this.data.length; i++) {
        this.aprs[this.data[i].address] = aprs[i];
      }*/
      const aprProviderClass = new AprProviderService([this.pool],this.prices,this.xpolarPoolQuery);
      aprProviderClass.init();
      aprProviderClass.aprsReceived = (aprs:any)=>{
        this.aprs = aprs;
      }
      aprProviderClass.fetchAll();
    },
  },
  setup(props) {
    /**
     * COMPUTED
     */
     const { isMobile, isDesktop } = useBreakpoints();
    /**
     * METHODS
     */
    function iconAddresses(pool: PoolWithShares) {
        return POOLS.Metadata[pool.id]?.hasIcon
            ? [pool.address]
            : orderedTokenAddresses(pool);
    }

    return {
      // computed
      isMobile,
      isDesktop,
      // methods
      iconAddresses,
      orderedPoolTokens
    };
  },
  created(){
    if(!this.noApr)
      this.fetchApr();
  }
});

